import React from "react";
import "./Layout.css";
import TitleImage from "./assets/img/title.png";
import { Box, Button, Card, CardContent, Grid, Hidden, Typography } from "@material-ui/core";
import { Youtube } from "./Youtube";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter, faSteam } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const cta = [
  {
    label: "Discord",
    icon: faDiscord,
    url: "https://discord.gg/Zv9ZStv8gv",
  },
  {
    label: "Twitter",
    icon: faTwitter,
    url: "https://twitter.com/ArkuniOfficial",
  },
  {
    label: "Mail me",
    icon: faEnvelope,
    url: "mailto:contact@arkuni.com",
  },
];

export const Layout = () => {
  return (
    <div className="container">
      <div className="content">
        <div>
          <img width="100%" src={TitleImage} />
        </div>
        <br />
        <br />
        <Card>
          <CardContent>
            <Youtube id="YyN_9elGfjs" />
            <br />
            <br />
            <Button component="a" href={"https://store.steampowered.com/app/1654310/Gang_of_Paws/"} variant="contained" color="secondary">
              <Box pt={1}>
                <FontAwesomeIcon icon={faSteam} size="10x" />
                <Box fontWeight="bold" textAlign="center" pt={0.5} fontSize={"2rem"} >
                  Play on Steam
                    </Box>
              </Box>
            </Button>
            <br />
            <br />
            <Grid container spacing={2} justify="center">
              {cta.map(x => (
                <Grid item>
                  <Button className="socialMediaButton" component="a" href={x.url} variant="contained" color="secondary">
                    <Box pt={1}>
                      <FontAwesomeIcon icon={x.icon} size="5x" />
                      <Box fontWeight="bold" textAlign="center" pt={0.5} >
                        {x.label}
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}