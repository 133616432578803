import React from "react";
import { AppBar, Box, Button, Grid, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter, faSteam } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Header = () => {
  return (
    <>
      <Toolbar />
      <AppBar position="fixed">
        <Toolbar>
          <Box display="flex">
            <Link to="/">
              <Typography variant="h6" noWrap>
                Gang of Paws
              </Typography>
            </Link>
          </Box>
          <Box flexGrow={1} />
          <IconButton component="a" href="https://store.steampowered.com/app/1654310/Gang_of_Paws/" color="inherit">
            <FontAwesomeIcon icon={faSteam} />
          </IconButton>
          <IconButton component="a" href="https://discord.gg/Zv9ZStv8gv" color="inherit">
            <FontAwesomeIcon icon={faDiscord} />
          </IconButton>
          <IconButton component="a" href="https://twitter.com/ArkuniOfficial" color="inherit">
            <FontAwesomeIcon icon={faTwitter} />
          </IconButton>
          <IconButton component="a" href="mailto:contact@arkuni.com" color="inherit">
            <FontAwesomeIcon icon={faEnvelope} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}