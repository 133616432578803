import "./Youtube.css";
import react, { FC } from "react";

export interface YoutubeProps 
{
    id: string;
}

export const Youtube: FC<YoutubeProps> = props => 
(
    <div className="youtube-container">
      <iframe
        width="100%"
        height="100%"
        className="youtube-video"
        src={`https://www.youtube.com/embed/${props.id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Gang of Paws Trailer"
      />
    </div>
  );