import "./App.css";
import React from "react";
import { Header } from "./Header";
import { BrowserRouter as Router, } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { Layout } from "./Layout";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000f1e",
    },
    secondary: {
      main: "#ff4500"
    },
    text: {
      primary: "#FFFFFF"
    },
    background: {
      paper: "#000f1e",
    },
  },
});

export const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router>
          <Header />
          <Layout />
      </Router>
    </ThemeProvider>
  );
}